import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../layout';
import SEO from '../components/SEO';
import Social from '../templates/social';
import { HeaderSimple } from '../components/headers';
import SubscribeForm from '../components/subscribe/SubscribeForm';
import Image from '../components/bio/avatar';

const aboutQuery = graphql`
  query aboutQuery {
    site {
      siteMetadata {
        author
        twitterUsername
      }
    }
  }
`;

function Bio() {
  return (
    <StaticQuery
      query={aboutQuery}
      render={data => {
        const { author } = data.site.siteMetadata;
        return (
          <Layout>
            <SEO title="About" path="/about" />
            <div className="card border-0 text-center">
              <div className="card-body border-0">
                <HeaderSimple title="Me" />
              </div>
            </div>
            <div className="container">
              <div className="row align-content-center">
                <div className="col-md-6 align-self-center">
                  <div className="card border-0">
                    <div className="card-body">
                      <h5 className="card-title text-center">{author}</h5>
                      <p className="card-text text-muted text-center">
                        The best things in life are those that spark your interest. Those that call to your soul.
                      </p>
                    </div>
                    <div className="card-body text-center">
                      <Social />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <Image />
                </div>
              </div>
            </div>
            <div className="col-md-8 pb-4 mx-auto">
              <SubscribeForm />
            </div>
          </Layout>
        );
      }}
    />
  );
}

export default Bio;
